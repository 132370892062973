import { ref, reactive } from 'vue'
import router from "/src/router.js"
import axios from "axios"

import { appStoreLInk } from '/src/assets/js/model'

import terms_of_use from "/src/assets/pdf/Fプレミアム約款.pdf"

export const terms_of_use_pdf = terms_of_use

/**
 * アプリ必要なバージョン
 */
const appRequiredVersion = "3.0.0"

export const ftRoute = reactive({}) 

export const ftWebApi = axios.create({
    baseURL: "/api/"
})

export const apiMethodBase = {
    site: "top",
    mypage: "user"
}

export const loading = reactive({})
export const userInfo = ref('')
export const isNativeApp = ref(false)
/**
 * 利用しているディバイスのOS
 */
export const deviceOs = ref("")
export const appVersionInfo = reactive({
    versionCheckFinished: false,
    updateRequired: false,
    appStatus: ""
})


export const alert = reactive({
    status: false,
    message: "",
})

// ChatLeader部分
export const showChatdelar = () => {
    let alreadyOpen = false
    const scripts = document.getElementsByTagName("script")
    Array.prototype.slice.call(scripts).forEach(script => {
        if(script.attributes[1] && script.hasAttribute('src') && script.getAttribute('src').includes("chatdealer")){
            alreadyOpen = true
        }
    })
    if(!alreadyOpen){
        try{
            var ins=document.createElement('script'),dt=new Date,tg=document.getElementsByTagName('script')[0];
            ins.type='text/javascript';
            ins.async=!0;
            ins.setAttribute('charset','utf-8');
            ins.src=vgProtocol+'://'+vgHost+':'+vgPort+'/chat/client.js?'+dt.getTime();
            tg.parentNode.insertBefore(ins,tg);
        }catch(e){
            console.log(e);
        }
    }else{
        alert.status = true
        alert.note = "右下から選択してお願いいたします"
        alert.okBtn = true
    }
}

export const companyInfo = ref({})
export const notiTypeId = ref(0)
export const paginationInfo = reactive({})

export const logout = async () => {
    alert.status = true
    alert.mark = "!"
    alert.message = "ログアウトしますか？"
    alert.cancelBtn = true
    alert.okBtn = true
    alert.function = async() => {
        const retData = await ftWebApi.post(`user/logout`);
        if(retData.data.status == "ok"){
            if(isNativeApp.value){
                deleteLoginTokenToApp[deviceOs.value](userInfo.value.unique_id)
            }
            router.push("/login")
        }
    }
}

export const saveLoginTokenToApp = {
    android: (loginToken, userUniqueId) => {
        window.ftGroupAppJsBridge.saveLoginToken(loginToken, userUniqueId);
    },
    ios: (loginToken, userUniqueId) => {
        const info = {
            loginToken,
            userUniqueId
        }
        window.webkit.messageHandlers.saveLoginToken.postMessage(info)
    },
}
export const deleteLoginTokenToApp = {
    android: () => {
        window.ftGroupAppJsBridge.delLoginToken();
    },
    ios: () => {
        window.webkit.messageHandlers.deleteLoginToken.postMessage("deleteLoginToken")
    },
}

export const appVersionChecking = {
    android: () => {
        return window.ftGroupAppJsBridge.appVersionChecking(appRequiredVersion);
    },
    ios: () => {
        const info = {
            msg: "getAppVersion",
            requiredVersion: appRequiredVersion
        }
        window.webkit.messageHandlers.appVersionChecking.postMessage(info)
    },
}

export const appVersionUpdateAlert = () => {
    const env = window.location.host.split(".")[0].charAt(0)
    loading.alert = true
    setTimeout(() => {  
        alert.status = true
        alert.message = "アプリのアップデートお知らせ"
        alert.note = `OKボタンを押してアップデートしてください。<br>
        <small class="text-danger">
            OKボタンを押してもGoogle Playstoreが立ち上がらない場合は、Google Playstoreより更新を御願い致します。
        </small>
        `
        alert.okBtn = true
        alert.forApp = true
        alert.function = () => {
            delete alert.forApp
            if(deviceOs.value === "android" && appVersionInfo.appStatus === "old") {
                if(env == "d"){
                    window.location.href = "https://development.f-premium.jp/howtoupdate"
                }else if(env == "s"){
                    window.location.href = "https://staging.f-premium.jp/howtoupdate"
                }else{
                    window.location.href = "https://f-premium.jp/howtoupdate"
                }
            }else{
                window.location.href = appStoreLInk[deviceOs.value]
            }
        }
        delete loading.alert
    }, 3000);
}
const removeIdElement = (id) => {
    const removeEle = document.getElementById(id)
    if(removeEle){
        removeEle.remove()
        removeIdElement(id)
    }
}
export const removeChatDealer = () => {
    localStorage.removeItem("PMR37Chat3-ec_4_vt");
    removeIdElement("vgCtaModalDivWrap")
    
    const iframeElement = document.getElementsByTagName("iframe")
    Array.prototype.slice.call(iframeElement).forEach(iframe => iframe.remove())
    const scripts = document.getElementsByTagName("script")
    Array.prototype.slice.call(scripts).forEach(script => {
        if(script.attributes[1] && script.hasAttribute('src') && script.getAttribute('src').includes("chatdealer")){
            console.log("script ", script.getAttribute('src'))
            script.remove()
        }
    })
}